//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
export default {
  name: 'login',
   head () {
            return {
                bodyAttrs: {
                class: 'w-store account-login'
                }
            }
        },

  data() {
        return {
            mobile_number:'',
            mobile_error:'',
            mobile:false,
            otp_number:'',
            otp_error:'',
            otp:false,
            from_path:'',
            resend_otp_time:60,
            reCaptchaToken: ""
        };
    },
    beforeRouteEnter(to, from, next) 
    {
        next((vm) => {
            vm.from_path = from.path;
        });
    },
   methods: {
    async getRecaptchaToken() {
      try {
             this.reCaptchaToken = await this.$recaptcha.execute('login')
        } catch(e) {
           }
    },
       countDownTimer() {
            if(this.resend_otp_time > 0) {
                setTimeout(() => {
                    this.resend_otp_time -= 1
                    this.countDownTimer()
                }, 1000)
            }
            if(this.resend_otp_time == 0) {
                this.resend_otp_time = 60
            }
        },
       async resend_otp(){
            this.$store.state.list.error_message = '';
            this.$store.state.list.success_message = '';
            this.resend_otp_time = 60;
            this.countDownTimer();
            await this.getRecaptchaToken()
            var form = new FormData();
            form.append("phone",this.mobile_number);
            form.append("reCaptchaToken", this.reCaptchaToken)
            let config = { headers: { 'Content-Type': 'multipart/form-data' } }
            axios.post(this.$store.state.list.cart_api_url+'/customer/send-otp', form , config)
            .then(response => {
                if(response.data.success === true)
                {
                    this.$store.state.list.error_message = '';
                    this.$toast.success(response.data.message, {
                        position: 'top',
                        duration:4000,
                    })
                    
                }
                else
                {
                    this.$store.state.list.success_message = '';
                    this.$toast.error(response.data.message, {
                        position: 'top',
                        duration:4000,
                    })
                }
            })
            .catch(error =>
            {
                this.$store.state.list.page_loader = false;
                if(error.message === "Network Error"){
                    this.$toast.error('Oops there seems to be some Network issue, please try again.', {
                        position: 'top',
                        duration:4000,
                    })
                }
                window.scrollTo(0,0);
            })
        },
        mobile_no: async function(){
            this.resend_otp_time = 60;
            var phoneno = /^([9867]{1})([1234567890]{1})([0-9]{8})$/;
            if (this.mobile_number.match(phoneno)) {
                this.mobile_error = "";
                if(this.mobile_number.length == '10')
                {
                    this.mobile = true;
                    this.$store.state.list.error_message = '';
                    this.$store.state.list.success_message = '';
                    await this.getRecaptchaToken()
                    setTimeout(() => {this.$refs.otp.focus();}, 0)
                    var form = new FormData();
                    form.append("phone",this.mobile_number);
                    form.append("reCaptchaToken", this.reCaptchaToken)
                    let config = { headers: { 'Content-Type': 'multipart/form-data' } }
                    axios.post(this.$store.state.list.cart_api_url+'/customer/send-otp', form , config)
                    .then(response => {
                        if(response.data.success === true)
                       {
                            this.$toast.success(response.data.message, {
                                position: 'top',
                                duration:4000,
                            })
                           this.$store.state.list.error_message = '';
                          this.$gtm.push({
                                event: 'Login',
                                category: 'Login',
                                action: 'OTP Sent'
                            });
                       }
                       else
                       {
                           this.$toast.error(response.data.message, {
                            position: 'top',
                            duration:4000,
                        })
                           this.$store.state.list.success_message = '';
                       }
                    })
                    .catch(error =>
                    {
                        this.$store.state.list.page_loader = false;
                        if(error.message === "Network Error"){
                           this.$toast.error('Oops there seems to be some Network issue, please try again.', {
                                position: 'top',
                                duration:4000,
                            })
                        }
                        window.scrollTo(0,0);
                    }) 
                }
                else{
                  this.mobile = false;
                  this.otp = false;
                  this.otp_number = "";
                }
            }
            else {
                this.mobile = false;
                this.mobile_error = "Please enter valid mobile number";
            }
       }
    },
    // created () {
    //      if(this.$store.state.list.customer_id != null && this.$store.state.list.customer_id != ''){
    //        this.$router.push('account-dashboard')
    //      }
    //      this.$store.state.list.error_message = '',
    //      this.$store.state.list.success_message = '',
    //      this.$store.state.list.page_loader = false;
    // },
   async mounted () {
       try {
        await this.$recaptcha.init()
      } catch (e) {
       console.error(e);
      }
        if(this.$store.state.list.customer_id) {
            this.$toast.success("Already Logged In.", {
                position: 'top',
                duration:4000,
            })
           
            this.$router.push(this.from_path)
        }

    },
    watch: {
      otp_number: {
      handler: function (after, before) {
         var otpno = /^\d*(?:\.\d{1,2})?$/;
            if (this.otp_number.match(otpno)) {
                this.otp_error = "";
                if(this.otp_number.length == '4')
                {   
                    this.$store.state.list.error_message = '';
                    this.$store.state.list.success_message = '';
                    this.$store.state.list.page_loader = true;
                    this.otp = true;
                    this.$store.state.list.customer_id = null;
                    var form = new FormData();
                    form.append("phone",this.mobile_number);
                    if(this.$store.state.list.cart_id != null && this.$store.state.list.cart_id != ''){
                         form.append("cart_id",this.$store.state.list.cart_id);
                    }
                    if(this.$store.state.list.cart_session != null && this.$store.state.list.cart_session != ''){
                        form.append("cart_session",this.$store.state.list.cart_session);
                    }
                    form.append("store",this.$store.state.list.store);
                    form.append("otp",this.otp_number);
                    form.append("track_id",localStorage.getItem('xCasaTrackerId'))
                    axios.post(this.$store.state.list.cart_api_url+'/customer/validate-otp', form)
                    .then(response => {
                       if(response.data.success === true)
                       {
                        this.$store.state.list.page_loader = false;
                        this.$store.state.list.login_popup = false;
                        this.$store.state.list.error_message = '';
                        $cookies.set(window.location.hostname.substring(10, 4)+'_customer', response.data.data.customer.id , "1y");
                        this.$toast.success(response.data.message, {
                            position: 'top',
                            duration:4000,
                        })
                        
                        $cookies.set(window.location.hostname.substring(10, 4)+'_cus_sess', response.data.data.customer_session , "1y");
                        this.$store.state.list.customer_session = response.data.data.customer_session;
                        
                        if(response.data.data.cart_session){
                        $cookies.set(window.location.hostname.substring(10, 4)+'_ct_sess', response.data.data.cart_session , "1y");
                        this.$store.state.list.cart_session = response.data.data.cart_session;
                        }
                        this.$store.state.list.customer_id = response.data.data.customer.id*1;
                        if(response.data.data.cart_id){
                           this.$store.state.list.cart_id = response.data.data.cart_id;
                           this.$store.state.list.thankyou_cart = response.data.data.cart_id;
                           $cookies.set(window.location.hostname.substring(10, 4)+'_cart', response.data.data.cart_id);
                        }
                        this.$store.state.list.customer_detail =  response.data.data.customer;
                        if(response.data.data.products){
                           this.$store.state.list.cart_product = response.data.data.products;
                            this.$store.state.list.cart_total = response.data.data.grand_total;
                        }
                        this.$store.state.list.address = response.data.data.address;
                        if(this.$store.state.list.address.length != 0){
                            for(let i = 0; i < this.$store.state.list.address.length; i++){
                            if(i == 0)
                            {
                                this.$store.state.list.address_id = this.$store.state.list.address[i].id*1;
                                this.$store.state.list.select_address_no = i;
                            }
                            }
                        }
                       this.$gtm.push({
                            event: 'Login',
                            category: 'Login',
                            action: 'Login Success'
                        });
                        this.$gtm.push({
                            event: 'Login_status',
                            category: 'Login_status',
                            action: 'Login Success',
                            Mobile_no: this.$store.state.list.customer_detail.phone,
                            Email: this.$store.state.list.customer_detail.email,
                        });
                        this.$router.push(this.from_path);
                        // this.router.push('/')
                        
                       }
                       else{
                        this.$store.state.list.success_message = '';
                        this.$store.state.list.page_loader = false;
                        this.$toast.error(response.data.message, {
                            position: 'top',
                            duration:4000,
                        })
                        this.$store.state.list.customer_id = null;
                        this.$gtm.push({
                            event: 'Login',
                            category: 'Login',
                            action: 'Login Failure',
                            label:response.data.message
                        });
                       }
                    })
                    .catch(error =>
                    {
                        this.$store.state.list.page_loader = false;
                        if(error.message === "Network Error"){
                            this.$toast.error('Oops there seems to be some Network issue, please try again.', {
                                position: 'top',
                                duration:4000,
                            })
                        }
                        window.scrollTo(0,0);
                    }) 
                }
                else{
                  this.otp = false;
                }
            }
            else {
                this.otp = false;
                this.otp_error = "Please enter valid OTP";
            }
      }
    },
    }
}
